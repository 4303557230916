import { i18n } from 'next-i18next';
import formatNumber from './formatNumber';

const formatHectares = (
  value: number | null | undefined,
  options?: {
    locale?: string;
    fallbackValue?: string;
    approximate?: boolean | null;
  },
) => {
  return formatNumber(value, {
    maximumFractionDigits: 4,
    unit: 'ha',
    locale: options?.locale || i18n?.language || 'fi',
    fallbackValue: options?.fallbackValue ?? '-',
    approximate: options?.approximate,
  });
};

export default formatHectares;
