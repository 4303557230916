export const approxAbbrev: Record<string, string> = {
  fi: 'n.',
  sv: 'ca',
  en: 'approx.',
};

export type FormatNumberOptions = {
  locale?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  unit?: string;
  fallbackValue?: string;
  approximate?: boolean | null;
  useGrouping?: boolean | null;
};

const formatNumber = (
  value: number | null | undefined,
  options?: FormatNumberOptions,
) => {
  const locale = options?.locale || 'fi';

  let formatted =
    value !== null && value !== undefined
      ? new Intl.NumberFormat(locale, {
          minimumFractionDigits: options?.minimumFractionDigits ?? undefined,
          maximumFractionDigits: options?.maximumFractionDigits ?? undefined,
          useGrouping: options?.useGrouping ?? true,
        }).format(value)
      : options?.fallbackValue ?? '';

  if (formatted && options?.approximate) {
    formatted = `${approxAbbrev[locale]} ${formatted}`;
  }

  if (formatted && options?.unit) {
    formatted = `${formatted} ${options.unit}`;
  }

  return formatted;
};

/**
 * Some fields expect numbers, but also allow
 * text (e.g. road cost, shorline length). So we
 * format them with this formatter.
 *
 * If the value seems to be only a number
 * (with either . or , as decimal point),
 * then we format it with formatNumber. Otherwise
 * we return the given value, or an empty string if
 * null or undefined.
 */
export const formatMaybeNumberString = (
  value: string | null | undefined,
  options?: FormatNumberOptions,
) => {
  if (value === null || value === undefined) {
    return '';
  }
  if (/^\d+[.,]?\d*$/.test(value)) {
    return formatNumber(parseFloat(value.replace(',', '.')), options);
  }
  return value;
};

export default formatNumber;
