import {
  intervalToDuration,
  differenceInCalendarDays,
  differenceInDays,
  differenceInHours,
  interval,
} from 'date-fns';
import { TFunction } from 'next-i18next';

const formatDuration = (
  duration: {
    end?: Date | number | string | null;
    start?: Date | number | string | null;
  },
  t: TFunction,
  options?: {
    fallbackValue?: string;
  },
) => {
  const { fallbackValue } = options || {};
  const { end: endArg, start: startArg } = duration;

  const start = startArg ? new Date(startArg) : new Date();
  const end = endArg ? new Date(endArg) : new Date();

  try {
    const intrvl = interval(start, end);

    const timeLeft = intervalToDuration(intrvl);

    const daysLeft = differenceInDays(end, start);
    const calDaysLeft = differenceInCalendarDays(end, start);
    const hoursLeft = differenceInHours(end, start);

    // more than 7 days left -> only show calendar days (i.e. full days) left
    if (daysLeft >= 7) {
      return t('frontoffice:duration_days', {
        days: calDaysLeft,
        defaultValue: '{{days}} pv',
      });
    }

    // more than 1 day left (but less than 7 days) -> show days and hours (only if not even hours)
    if (hoursLeft >= 24) {
      return `${t('frontoffice:duration_days', {
        days: daysLeft,
        defaultValue: '{{days}} pv',
      })} ${
        timeLeft.hours
          ? t('frontoffice:duration_hours', {
              hours: timeLeft.hours,
              defaultValue: '{{hours}} t',
            })
          : ''
      }`.trim();
    }

    // less than a day left -> show hours
    if (hoursLeft > 0) {
      return t('frontoffice:duration_hours', {
        hours: timeLeft.hours,
        defaultValue: '{{hours}} t',
      });
    }

    // less than an hour left
    if (hoursLeft === 0) {
      return t('frontoffice:duration_soon', 'Alle tunti');
    }
  } catch (e) {
    // TODO: use the new Logger when it's available
    console.error(e);
    return fallbackValue;
  }
};

export default formatDuration;
