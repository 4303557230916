import { Box, Tooltip, Tag, BoxProps, Icon } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { Globe } from '@phosphor-icons/react';
import { useRouter } from 'next/router';
import { PublicListing } from '@/types/listing';
import formatDuration from '@/lib/formatDuration';
import formatDate from '@/lib/formatDate';
import parseFinnishDateAndTime from '@/lib/parseFinnishDateAndTime';

const OfferTimeLeft = ({
  listing,
  withIcon,
  showInfinity,
  forceFinnishTime,
  ...props
}: {
  listing: Pick<PublicListing, 'publish_end_datetime' | 'is_public'>;
} & {
  withIcon?: boolean;
  showInfinity?: boolean;
  forceFinnishTime?: boolean;
  status?: string;
} & BoxProps) => {
  const { t } = useTranslation('frontoffice');
  const { locale } = useRouter();

  if (!listing.is_public) {
    return null;
  }

  let offerTimeLeft: string | undefined;
  let tooltip: string | undefined;

  if (listing.publish_end_datetime) {
    offerTimeLeft = formatDuration(
      { end: listing.publish_end_datetime as string },
      t,
      {
        fallbackValue: undefined,
      },
    );
    tooltip = t('offer_time_left', 'Tarjousaika päättyy {{date}}', {
      date: formatDate(listing.publish_end_datetime as string, {
        locale,
        dateStyle: 'medium',
        timeStyle: 'short',
        // Use Finnish time zone e.g. if we render this in
        // the backoffice where all times should be Finnish times
        timeZone: forceFinnishTime ? 'Europe/Helsinki' : undefined,
      }),
    });
  } else if (showInfinity) {
    offerTimeLeft = '∞';
    tooltip = t('offer_time_indefinite', 'Tarjousaika toistaiseksi voimassa');
  }

  return offerTimeLeft ? (
    <Box
      {...props}
      zIndex={2}
      aria-label={t('offer_time_label', 'Kohteen tarjousaika')}
      role="status"
    >
      <Tooltip openDelay={800} label={tooltip}>
        <Tag backgroundColor="warning.500" fontSize="xs">
          {withIcon && <Icon as={Globe} mr="1" />}
          {offerTimeLeft}
        </Tag>
      </Tooltip>
    </Box>
  ) : null;
};

export default OfferTimeLeft;
