import {
  Flex,
  Box,
  LinkBox,
  LinkBoxProps,
  Tag,
  Tooltip,
  LinkOverlay,
  LinkProps,
} from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { MapPin } from '@phosphor-icons/react';
import Image from '@/lib/Image';
import { listingImages } from '@/lib/listingUtils';
import { urls, publicContainerAttachmentUrl } from '@/lib/urls';
import { LimitedListingProps } from '@/types/listing';
import BasicData from './BasicData';
import Link from '../Link';
import OfferTimeLeft from './OfferTimeLeft';

const ListingCard = ({
  listing,
  href,
  target,
  ...rest
}: Pick<LimitedListingProps, 'listing'> & {
  href?: string;
  target?: LinkProps['target'];
} & LinkBoxProps) => {
  const { t } = useTranslation('frontoffice');

  // NOTE: basically unnecessaryish now since listing only has cover images
  const { coverImage } = listingImages(listing);
  const hasImage = !!coverImage?.attachment_public?.container;

  return (
    <LinkBox
      boxShadow="md"
      _hover={{ boxShadow: 'lg' }}
      aria-label={listing.name || ''}
      transition="box-shadow 0.15s"
      borderRadius="lg"
      overflow="hidden"
      backgroundColor="white"
      position="relative"
      as="article"
      {...rest}
    >
      <Flex
        width="100%"
        position="relative"
        background="gray.300"
        _before={
          hasImage
            ? undefined
            : {
                content: "''",
                pb: `${((1 / (576 / 317)) * 100).toFixed(4)}%`, // keep image constrain
              }
        }
      >
        <OfferTimeLeft
          listing={listing}
          position="absolute"
          left="3"
          top="3"
          zIndex="20"
        />
        {listing.distance ? (
          <Box position="absolute" right="3" top="3" zIndex="20">
            <Tooltip
              label={t('straight_line_distance', 'Etäisyys linnuntietä')}
            >
              <Tag>
                <MapPin size={18} />{' '}
                {listing.distance >= 100
                  ? Math.round(listing.distance / 10) * 10
                  : Math.round(listing.distance as number)}{' '}
                km
              </Tag>
            </Tooltip>
          </Box>
        ) : null}
        {hasImage && (
          <Image
            width="576px"
            height="317px"
            placeholder={
              coverImage?.attachment_public.blurdata_url ? 'blur' : 'empty'
            }
            blurDataURL={
              coverImage?.attachment_public.blurdata_url || undefined
            }
            src={
              publicContainerAttachmentUrl(coverImage?.attachment_public) ?? ''
            }
            alt={
              listing.name
                ? t('cover_image_for', 'Kansikuva kohteelle {{ name }}', {
                    name: listing.name,
                  })
                : t('cover_image', 'Kansikuva')
            }
          />
        )}
      </Flex>
      <BasicData listing={listing}>
        <Link
          href={href || urls.public.listing.get(listing.public_id ?? '')}
          as={LinkOverlay}
          target={target}
        >
          {listing.name}
        </Link>
      </BasicData>
    </LinkBox>
  );
};

export default ListingCard;
