const formatDate = (
  value?: Date | string | null,
  options?: {
    locale?: string;
    fallbackValue?: string;
  } & Intl.DateTimeFormatOptions,
) => {
  // As per the specs of Date.parse:
  // > When the time zone offset is absent, date-only forms
  // > are interpreted as a UTC time and date-time
  // > forms are interpreted as local time.
  //
  // When these dates come from the db, they're usually either
  // timestamps in UTC with timezone, or date strings as 'YYYY-MM-DD'.
  //
  // When the date is a timezone'd string, the Date object will
  // be timezone'd, so we want to display
  // Helsinki timezone to convert the UTC to Finnish time.
  //
  // When the date is a date-only string, the Date object will be
  // a UTC date, and thus we want to use UTC as timezone,
  // so we don't get a day-shift because of timezone offset.
  let timeZone = 'Europe/Helsinki';
  if (
    value &&
    typeof value === 'string' &&
    value.match(/^\d{4}-\d{2}-\d{2}$/)
  ) {
    timeZone = 'UTC';
  }
  const { locale, fallbackValue, ...intlOptions } = options || {};
  return value
    ? new Intl.DateTimeFormat(locale || 'fi', {
        timeZone,
        ...intlOptions,
      }).format(new Date(value))
    : fallbackValue || '';
};

export default formatDate;
